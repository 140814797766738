import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import TopProducts from '../components/TopProducts';

class NoMatch extends Component {

    // constructor(props){
    //     super(props);
    //     this.state = {
    //         oldPath: ''
    //     }
    // }

    componentDidMount(){

    }
   
    render(){
        return(
            <div>
                <div className="left-content">
                    <TopProducts />
                </div>
                <div className="right-content">
                    <h1>Stranica nije pronađena</h1>
                    <p>Kliknite <Link to='/proizvodi' >ovde</Link> da pogledate naše naslove.</p>
                </div>
            </div>
        );
    }

}

export default NoMatch;