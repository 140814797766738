import React, { Component } from 'react';


class Sticker extends Component{
    render() {
      return(
        <div className={`sticker-container ${this.props.classNameProducts }`}>
            <img src="/images/arrow-discount-shadow.png" alt=""/>
            <p>{this.props.text}</p>
        </div>
      )
    }
  }


export default Sticker;