import React, { Component } from 'react';
import { Auth } from '../components/Auth';
import {Link, withRouter} from 'react-router-dom';
import duix from 'duix';


class Registration extends Component {

    constructor(props){
        super(props);
        this.state = {
            loggedIn: false,
            name: '',
        }

    }

    unsubscribe = [];

    update=()=>{
        let name = Auth.name;
            
            if(Auth.isAuthenticated){
                this.setState({name: name});
                this.setState({buttonText: 'izloguj se'}); 
                this.setState({loggedIn: true});
            }else{
                this.setState({buttonText: 'Prijava'}); 
                this.setState({loggedIn: false});
            }
            duix.set('wishlistItems', 1);
            
    }
 
    componentDidMount(){
        // setTimeout(()=>{
        //     this.update();
        // }, 1000);

        //this.unsubscribe[0] = duix.subscribe('wishlistItems', this.update);
        this.unsubscribe[1] = duix.subscribe('registration', this.update);
        
    }

    componentWillUnmount(){
        //this.unsubscribe[0]();
        this.unsubscribe[1]();
        //clearInterval(this.interval);
    }

    logout = () => {
        Auth.logout(()=>{
            
            // let name = Auth.name;
            // if(Auth.isAuthenticated){
            //     this.setState({name: name});
            //     this.setState({buttonText: 'Izloguj se'}); 
            //     this.setState({loggedIn: true});
            // }else{
            //     this.setState({buttonText: 'Prijava'}); 
            //     this.setState({loggedIn: false});
            // }
            this.update();
            duix.set('wishlistItems', Math.ceil(Math.random() * 100));
            this.props.history.push('/');
            
        });
    }

    render(){

        if(!this.state.loggedIn){
            return(
                <div className="registration-container">
                    <Link to="/registracija"><button className="register-button">Prijava</button></Link>
                </div>
            )
        }else{
            return(
                <div className="registration-container">
                    <span>{this.state.name} </span>
                    <button className="register-button" onClick={this.logout}>{this.state.buttonText}</button>
                </div>
            )
        }

    }

}

export default withRouter(Registration);