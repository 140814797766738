import React, { Component } from 'react';
import Service from '../components/Service';
import {Link} from 'react-router-dom';
import TopProducts from '../components/TopProducts';

function stripHTML(s){
    return s.replace(/<\/?[^>]+(>|$)/g, "");
}


class News extends Component {

    constructor(props){
        super(props);
        this.state = {
            news: []
        }

    }

    componentDidMount(){
        Service.getNews(
            (res)=>{
                this.setState({news: res.data})

                }
        );
    }
    
    render(){
        return(
            <div>
                <div className="left-content">
                    <TopProducts />
                </div>
                <div className="right-content">
                    <h2>Vesti</h2>
                    {this.state.news.map(function(item, index){
                        return(
                            <div key={index}>
                            <Link to={`/vest/${item.id}/${item.title}`}><h3>{item.title}</h3></Link>
                            <h4>{item.created_at}</h4>
                            <p>{stripHTML(item.text.slice(0, 100))}...</p>
                            <hr />
                            </div>
                        )
                    },this)
                    }
                </div>
            </div>
        );
    }

}

export default News;