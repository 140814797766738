import React, { Component } from 'react';
import Service from '../components/Service';


class UpdatePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            password2: ''
        }
    }

    updatePassword=()=>{
       Service.get('/users/updatePassword',{activationCode: this.props.match.params.activationCode},(res)=>{
            if(res.success){
                this.setState({activated: 1});
                this.props.history.push('/registracija/success');
            }else{
                this.setState({activated: 2});
            }
       })
    }

    handlePassword=(event)=>{
        this.setState({password: event.target.value});
    }
    handlePassword2=(event)=>{
        this.setState({password2: event.target.value});
    }

    submitResetPassword=()=>{
        Service.get('/users/updatePassword',{activationCode: this.props.match.params.activationCode,
         password: this.state.password,
         repeatedPassword: this.state.password2
         },(res)=>{
            if(res.success){
                this.props.history.push('/message/passwordChanged');
            }
        })
    }

    render(){
        return(
            <div>
                <h1>Promena lozinke</h1>
                <p>Nova lozinka:</p>
                <input type="password" value={this.state.password} onChange={this.handlePassword} />
                <p>Ponovite novu lozinku:</p>
                <input type="password" value={this.state.password2} onChange={this.handlePassword2} />
                <button className="default-button" type="button" onClick={this.submitResetPassword}>Pošalji</button>
            </div>
        )
    }
}

export default UpdatePassword;