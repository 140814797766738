import React, { Component } from 'react';

import TopProducts from '../components/TopProducts';
import NewsSidebar from '../components/NewsSidebar';




class About extends Component {


    
    render(){
        return(
            <div>
                <div className="left-content">
                    <TopProducts />
                    <NewsSidebar />
                    {/* <Categories /> */}
                </div>
                <div className="right-content">
                    <h1>
                        O nama
                    </h1>
                    <br />
                    <p>
                        Naš tim se sastoji od mladih i kreativnih ljudi koji se trude da čitaocima pruže kvalitetna i popularna dela svetske književnosti.
                    </p>
                    <br />
                    <p>
                        Jedan od ciljeva našeg izdavaštva je i podsticanje čitalačke kulture kod mladih i negovanje ljubavi prema knjizi. Otkupili smo prava i objavili romane koji su veoma aktuelni među mlađom populacijom, pre svega tinejdžerima. Među našim prevodima nalaze se najpoznatiji svetski autori.
                    </p>
                    <br />
                    <p>
                        U svojoj ponudi imamo i knjige za decu, koje kroz igru i zabavu podstiču razvoj opažanja, pamćenja i zaključivanja. Ponosni smo na činjenicu da smo stekli veliki krug svojih čitalaca.
                    </p>
                    <br /> 
                    <p>
                        Posebnu pažnju posvećujemo širenju školskog i predškolskog programa. Naše knjige i drugi nastavni materijali u potpunosti prate školski nastavni program.
                    </p>
                    <br />
                    <p>
                        Trudićemo se da u daljem radu pratimo najviše standarde i da na našem tržištu objavimo najkvalitetnije svetske hitove kako iz literature za decu i mlade tako i iz literature za odrasle.
                    </p>
                </div>
            </div>
        );
    }

}

export default About;