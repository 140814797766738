import React, { Component } from 'react';
import {Cart} from '../components/Cart';
import Service from '../components/Service';


class Success extends Component{

    constructor(props){
        super(props);

        // remove all cart items after successful purchase
        Cart.clearCart();
        this.state = {
            order: {},
            products: []
        }

    }

    getOrderInfo(){
        Service.get('/orders/getOrderInfo',{id: this.props.match.params.oid},(res)=>{
            this.setState({order: res.data.order[0], products: res.data.products});
        })
    }

    componentDidMount(){
        this.getOrderInfo();
    }
    

    render(){
        return(
            <div>
                <h1>Uspešno ste obavili kupovinu</h1>
                { this.props.match.params.tid === 'cc' &&
                    <div>
                        <p>ID Transakcije: {this.props.match.params.tid}</p>
                        {/* <p>Broj porudžbine: {this.props.match.params.oid}</p> */}
                        {/* <p>Ukupno: {this.props.match.params.ammount}</p> */}
                    </div>
                }
                <p>
                Broj porudžbine: {this.state.order.id}
                </p>

                <h3>{this.state.order.name}</h3>
                <p>{this.state.order.email}<br/>
                {this.state.order.phone}</p>

                <h2>Adresa</h2>
                    <p>
                        {this.state.order.billing_address}<br />
                        {this.state.order.zip_code} {this.state.order.city} <br />
                        {this.state.order.municipality}<br />
                        {this.state.order.country}<br />
                        {this.state.order.billing_address}<br />
                    </p>

                <h2>Artikli</h2>
                <div>
                    {this.state.products.map(function(item, index){
                        return(
                        <p><b>{item.productData.title}</b>, {item.unit_price} RSD x {item.quantity} KOM. ukupno: {item.price} RSD</p>
                        )
                    },this)
                    }
                </div>
                <p>Isporuka: {this.state.order.shipping_price} RSD</p>
                <p>Ukupno: {this.state.order.total} RSD</p>
                { this.props.match.params.type === 'pp' &&
                    <p>Način plaćanja: <strong>pouzećem</strong></p>
                }
                { this.props.match.params.type === 'cc' &&
                    <p>Način plaćanja: <strong>kreditnom karticom</strong></p>
                }


            </div>
        );
    }

}

export default Success;