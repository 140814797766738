import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component {

    constructor(props){
        super(props);
        this.state = {
            subscribed: false
        }

    }

    handleSubscription = () => {
        let s = this.state.subscribed;
        s = !s;
        this.setState({
            subscribed: s
        });
    }

    render(){
        return(
            <div className="footer">
                <div className="footer-top">
                    <img alt="" src="/logo.png" />
                    <h3>PUBLIK PRAKTIKUM</h3>
                    <p>DOBROVOLJAČKA 10/I, 11080 ZEMUN, SRBIJA +381 11 4202544 | +381 11 4202545</p>
                </div>
                <div className="footer-bottom-left">
                    <ul>
                        <li><Link to="/o-nama">O nama</Link></li>
                        <li><Link to="/kako-kupovati">Kako kupovati</Link></li>
                        <li><Link to="/pravila">Pravila korišćenja</Link></li>
                        <li><Link to="/dostava">Dostava</Link></li>
                        <li><Link to="/krcko">Krckov klub</Link></li>
                        <li><Link to="/kontakt">Kontakt</Link></li>
                    </ul>
                    
                    <p>Copyright © Publik Praktikum | <Link to='/kako-kupovati' >Polisa privatnosti</Link></p>
                    <p>LEGO, the LEGO logo, the Brick and Knob configurations, the Minifigure, FRIENDS logo, NINJAGO and NEXO KNIGHTS are trademarks of the LEGO Group. © 2016 The LEGO Group. Produced by AMEET under license from the LEGO Group.</p>
                </div>
                {/* <div className="footer-bottom-right">
                    <p><input className="home-checkbox" type="checkbox" checked={this.state.subscribed} onClick={this.handleSubscription} />Prihvatam da primam email obaveštenja</p>
                    <input type="text" placeholder="vas email..." /><button> POŠALJI </button>
                </div> */}

                {/* bank card images */}
                <div className="footer-logos">
                    <div>
                        <img alt="" src="/images/cards.png" />
                    </div>
                    <div>
                        <a href="https://www.bancaintesa.rs/pocetna.1.html" target="_blank" rel="noopener noreferrer">
                        <img alt="" src="/images/bintesa.png" />
                        </a>
                    </div>
                    <div>
                        <a href="https://rs.visa.com/rs/rs-rs/protectedeverywhere/index.html" target="_blank" rel="noopener noreferrer">
                            <img alt="" src="/images/learn1.jpg" />
                        </a>
                        <a href="https://www.mastercard.us/en-us.html" target="_blank" rel="noopener noreferrer">
                            <img alt="" src="/images/learn2.jpg" />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;