import React, { Component } from 'react';


class Shipping extends Component{

    constructor(props){
        super(props);
        this.state = {
            address: '',
            city: '',
            country: 'Srbija',
            email: '',
            municipality: '',
            name: '',
            telephone: '',
            zipCode: '',
            nameError: 'none',
            addressError: 'none',
            cityError: 'none',
            zipCodeError: 'none',
            emailError: 'none',
            telephoneError: 'none',
        }
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value},
            ()=>{this.props.updateShippingData(this.state)}
        );
    }

    render(){
        return(
            <div>
                <h3>Adresa isporuke:</h3>
                <div className="cart-inputs">
                    <h3>Ime i prezime isporuke</h3>
                    <input type="text" id='name' value={this.state.name} onChange={this.handleInput} />
                    <p className="error" style={{display: this.state.nameError}}>* morate da unesete ime i prezime.</p>
                    <h3>Adresa isporuke</h3>
                    <input type="text" id='address' value={this.state.address} onChange={this.handleInput} />
                    <p className="error" style={{display: this.state.addressError}}>* morate da unesete adresu.</p>
                    <h3>Opstina isporuke</h3>
                    <input type="text" id='municipality' value={this.state.municipality} onChange={this.handleInput} />
                    <h3>Grad isporuke</h3>
                    <input type="text" id='city' value={this.state.city} onChange={this.handleInput} />
                    <p className="error" style={{display: this.state.cityError}}>* morate da unesete grad.</p>
                </div>
                <div className="cart-inputs">
                    <h3>Poštanski broj isporuke</h3>
                    <input type="text" id='zipCode' value={this.state.zipCode} onChange={this.handleInput} />
                    <p className="error" style={{display: this.state.zipCodeError}}>* morate da unesete poštanski broj.</p>
                    <h3>Država isporuke</h3>
                    {/* <input type="text" id='country' value={this.state.country} onChange={this.handleInput} /> */}
                    <input type="text" id='country' value='Srbija' readonly/>
                    <h3>Broj telefona isporuke</h3>
                    <input type="text" id='telephone' value={this.state.telephone} onChange={this.handleInput} />
                    <p className="error" style={{display: this.state.telephoneError}}>* morate da unesete vaš broj telefona.</p>
                    <h3>Email isporuke</h3>
                    <input type="text" id='email' value={this.state.email} onChange={this.handleInput} />
                    <p className="error" style={{display: this.state.emailError}}>* morate da unesete vaš email.</p>
                </div>
            </div>
        );
    }

}

export default Shipping;