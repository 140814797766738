import React, { Component } from 'react';
import Service from '../components/Service';


class Activation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activated: 0
        }
    }
   
    messages = {
        0: 'Aktivacija je u toku.',
        1: 'Uspešno ste aktivirali vaš nalog.',
        2: 'Aktivacija nije uspela, molimo vas pokušajte ponovo.'
    }

    componentDidMount(){
        this.activate();
    }

    activate=()=>{
       console.log('ACT: ',this.state.activated);
       Service.get('/users/accountstatusactivation',{activationCode: this.props.match.params.activationCode},(res)=>{
            if(res.success){
                this.setState({activated: 1});
                this.props.history.push('/registracija/success');
            }else{
                this.setState({activated: 2});
            }
       })
    }
    
    render(){
        return(
            <div>
                <h2>Aktivacija {this.props.match.params.activationCode}</h2>
                <p>{this.messages[this.state.activated]}</p>
            </div>
        );
    }

}

export default Activation;