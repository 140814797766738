import React, { Component } from 'react';
import { Cart } from '../components/Cart';
// import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import Service from '../components/Service';

// function images(image){
//     return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
// }


class ProductView extends Component {


    addToCart = (product) => {
      Cart.addToCart(product);
      Cart.getCart();
    }

    render() {
        return (
            <div className="product-view" >
            <a href={'/proizvod/'+this.props.item.id+'/'+this.props.item.slug.toLowerCase()} >
                <img alt="" src={Service.images(this.props.item.slug.toLowerCase() + '.jpg')} height="160" />
            </a>
            <h3>{this.props.item.title}</h3>
            {this.props.item.authors.map(function(author, index){
                // if(index < 4){
                return(
                    
                    <span className="product-view-author" key={index}>
                        {author.name}
                        {/* <p>{author.name}</p> */}
                    </span>
                    
                )
                // }
                }, this)
            }
            {/* <button className="add-to-cart-button" onClick={()=>{this.addToCart(this.props.item)}}>Dodaj u korpu</button> */}
            </div>
        );
    }

}

export default ProductView;
