import React, { Component } from 'react';
//import ProductView from '../components/ProductView';
//import Slider from "react-slick";
import {Link} from 'react-router-dom';
//import renderHTML from 'react-render-html';
import Service from '../components/Service';
import Slider from '../components/Slider';

function stripHTML(s){
    let n = s.replace(/(<([^>]+)>)/ig,"");
    let a = n.split('&nbsp;');
    return a.join(' ');
}


// function images(image){
//     return 'http://api.pp.cekaonica.com/media/news/'+ image;
// }

// const sizes = {
//     1: '100%',
//     2: '50%',
//     3: '33%',
//     4: '25%',
//     5: '20%'
// }

class NewsSlider extends Component {

    render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
        // <Slider perPage={2} responsivePerPage={1} auto={false} duration={.4}>

      //<Slider {...settings}>
          this.props.news.map(function(item, index){
              if(index < 3){
                    return(
                        // <li key={index} style={{width: sizes[this.props.perPage]}}>
                        //<ProductView item={item} key={index} />
                        // </li>
                        
                        <div className='news-slider-container'>
                            <div>
                                {/* <Link to={`/vest/${item.id}`}><img src={images(item.image)}  /></Link> */}

                                <Link to={`/vest/${item.id}`}><div className="image" style={{ backgroundImage: "url(" + Service.images('news/'+item.image) + ")"}}  /></Link>
                                <h3>{item.title}</h3> 
                                {/* <p>{stripHTML(item.text.slice(0, 75))}...</p>  */}
                                <p>{stripHTML(item.text).slice(0, 110)}...<Link to={`/vest/${item.id}`}>Procitaj više {'>'}</Link></p> 
                            </div>
                        </div> 
                    )
                }
            },this)
                
    //   </Slider>
    );
  }


}

export default NewsSlider;
