import React, { Component } from 'react';
import Categories from '../components/Categories';


class Rules extends Component{

    render(){
        return(
            <div>
                <div className="left-content">
                        <h3>Kategorije</h3>
                        <Categories />
                </div>
                <div className="right-content">
                    <h2>
                        Pravila korišćenja
                    </h2>
                    <h3>
                        Opšte odredbe
                    </h3>
                    <p>
                        <br />Prilikom korišćenja internet stranice Publikpraktikum.rs obavezni ste da poštujete važeće zakone i propise. Sadržaj web sajta se ne sme modifikovati, distribuirati, reprodukovati, prodavati, kopirati ili na bilo koji drugi način upotrebljavati i činiti dostupnim trećim licima.
                        <br />Zabranjeno je na web sajtu objavljivati materijal kojim se vrši kleveta, povreda prava, koji je uvredljiv, lažan, preteći, vulgaran, pornografski, ili na bilo koji način podstiče na krivično ili protivzakonito delo. Publik Praktikum zadržava pravo da ovakav materijal ukloni sa sajta bez prethodnog upozorenja korisnika.
                        <br />Zabranjeno je svako lažno predstavljanje i objavljivanje netačnih podataka u vezi sa sajtom ili osobama vezanim za isti.
                        <br />Zabranjeno je korišćenje sajta Publikpraktikum.rs u propagandne svrhe.
                        <br />Zabranjeno je svako korišćenje sajta Publikpraktikum.rs radi preprodaje robe.
                        <br />Zabranjeno je postavljanje virusa i svih komjuterskih kodova, datoteka i programa koji na bio koji način mogu oštetiti ili preuzeti kontrolu nad sajtom ili pratećom opremom.
                        <br />Stranice i sadržaj našeg sajta možete koristiti informativno i za kupovinu sve robe koja je na sajtu objavljena.
                        <br />Sajt Publikpraktikum.rs je prodajno mesto čije je radno vreme svakodnevno 24 sata. Vanredno, prodajno mesto može biti nedostupno zbog tehničkog održavanja ili nepredviđenih okolnosti izvan kontrole Publik praktikum-a.
                    </p>
                    <h3>
                        Izjava o zaštiti privatnosti i prikupljanju ličnih podataka korisnika i njihovom korišćenju
                    </h3>
                    <h4>
                        Polisa privatnosti
                    </h4>
                    <p>
                        U ime PUBLIK PRAKTIKUM obavezujemo se da ćemo čuvati privatnost svih naših kupaca. Prikupljamo samo neophodne, osnovne podatke o kupcima/korisnicima i podatke neophodne za poslovanje i informisanje korisnika u skladu sa dobrim poslovnim običajima i u cilju pružanja kvalitetne usluge. Dajemo kupcima mogućnost izbora uključujući mogućnost odluke da li žele ili ne da se izbrišu sa mailing lista koje se koriste za marketinške kampanje. Svi podaci o korisnicima/kupcima se strogo čuvaju i dostupni su samo zaposlenima kojima su ti podaci nužni za obavljanje posla.
                    </p>
                    <p>
                        Svi zaposleni PUBLIK PRAKTIKUM (i poslovni partneri) odgovorni su za poštovanje načela zaštite privatnosti.
                    </p>
                    <h3>
                        Modeli obaveštenja korisnicima
                    </h3>
                    <ul>
                        <li>
                            Oglasi
                        </li>
                        <li>
                            Lifleti
                        </li>
                        <li>
                            Newsletteri
                        </li>
                        <li>
                            Poštanska pisma
                        </li>
                        <li>
                            Katalozi
                        </li>
                        <li>
                            Telefonski pozivi
                        </li>
                    </ul>
                    <h3>
                        Tipovi prikupljenih informacija
                    </h3>
                    <p>
                        Informacije koje izdavačka kuća PUBLIK PRAKTIKUM može prikupljati su: 
                        <br />
                        - Vaše kontakt informacije (ime, prezime, poštanska i e-mail adresa, broj telefona) 
                        <br />
                        - Informacije za naplatu i isporuku (adresa za isporuku) 
                        <br />
                        - Informacije ličnog izbora (lista željenih proizvoda, komentari o ponuđenim proizvodima…) 
                    </p>
                    <h3>
                        Proces prikupljanja informacija
                    </h3>
                    <p>
                        Tokom kupovine i korišćenja naših servisa ili usluga, možemo Vam zatražiti Vaše lične informacije, a to se može desiti samo u sledećim slučajevima: 
                        
                        <br />- Prilikom online registracije korisničkog naloga
                        
                        <br />- Pri kupovini nekog od proizvoda
                        
                        <br />- Korišćenjem nekog servisa ili neke druge usluge 
                        
                        <br />- Prilikom dostave porudžbine
                        
                        <br />- Učestvovanjem u nagradnim igrama, poklonima, anketama i istraživanjima 
                        
                        <br />- Uspostavljanjem kontakta preko Web stranica ili direktno u centrali PUBLIK PRAKTIKUM
                        
                        <br />- Učestvovanjem u drugim aktivnostima naše kuće
                    </p>
                    <p>
                        Prikupljene informacije koriste se isključivo u sledećim slučajevima:
                        <br />
                        - Za popunjavanje formi u zahtevima za informacijama 
                        <br />
                        - Za obezbeđivanje i pružanje korisničkih servisa 
                        <br />
                        - Za organizaciju i administraciju nagradnih igara, poklona, anketa i istraživanja 
                        <br />
                        - Za ponudu novih proizvoda i servisa 
                        <br />
                        - Za poboljšanje efektivnosti naših Web stranica, marketinških aktivnosti, servisa i ponude 
                        <br />
                        - Za sprovođenje istraživanja i analiza
                        <br />
                        - Za slanje informacija u marketinške svrhe
                        <br />
                        - Za obavljanje drugih poslovnih aktivnosti 
                    </p>
                    <p>
                        Ako nam dostavite informaciju o drugim osobama ili ako druge osobe dostave informacije o Vama, iste će biti korišćene striktno u svrhe za koje su i dostavljene. Kao primer ovog tipa informacija možemo da navedemo adresu prijatelja na koju će biti dostavljena porudžbina ili e-mail adresa prijatelja koja je zabeležena preko opcije “Preporučite prijatelju” ili preko nekih drugih opcija. 
                    </p>
                    <h3>
                        Prosleđivanje ličnih informacija
                    </h3>
                    <p>
                        PUBLIK PRAKTIKUM ne prodaje i ne iznajmljuje Vaše lične podatke trećim licima. 
                    </p>
                    <h3>
                        Mogućnost ne prihvatanja marketinških aktivnosti
                    </h3>
                    <p>
                        Kada prikupimo vaše podatke, zadržavamo ih u sistemu  i možemo Vam slati marketinška obaveštenja koja sadrže povoljne ponude, promocije, prodajne akcije i ostale sadržaje. Ali Vi u svakom trenutku imate opciju ne prihvatanja budućih marketinških obaveštenja. 
                    </p>
                    <p>
                        Za prestanak primanja marketinških obaveštenja, molimo Vas da preduzmete neku od sledećih opcija: 
                    </p>
                    <ul>
                        <li>
                            Sledite uputstva za odjavu u primljenim marketinškim obaveštenjima koja su poslata sa naše strane. 
                        </li>
                        <li>
                            Putem slanja e-maila na adresu office@publikpraktikum.rs sa temom “Odjava”. 
                        </li>
                        <li>
                            Putem poštanske adrese : 
                        </li>
                    </ul>
                    <p>
                        PUBLIK PRAKTIKUM
                        <br />
                        Dobrovoljačka 10/I
                        <br />
                        11080 Zemun
                    </p>
                    <p>
                        Pri slanju e-maila ili poštanskog pisma, molimo Vas da upišete svoje puno ime i prezime, adresu, broj telefona, e-mail adresu i da specifično naznačite koji tip marketinškog oglašavanja (npr. e-mail, direktna poštanska pisma ili telefonske pozive) ne želite da dobijate. Na taj način će biti osigurana Vaša identifikacija u našem sistemu kako bi uspešno procesirali Vaš zahtev.  
                    </p>
                    <p>
                        PUBLIK PRAKTIKUM će preduzeti neophodne korake kako bi implementirali Vaš zahtev za prestanak dobijanja marketinških obaveštenja. Molimo Vas da notirate da zbog obrade podataka, poštanskih i sistemskih rokova, ova procedura može potrajati 10 radnih dana za e-mail poruke i 6 nedelja za direktno slanje pošte. Dok ova promena ne stupi na snagu, moguće je da ćete u tom periodu i dalje dobijati naša marketinška obaveštenja. 
                    </p>
                    <p>
                        Takođe treba notirati da i kada se odjavite od prijema marketinških obaveštenja, moguće je da ćete i dalje dobijati poslovna obaveštenja kao što su potvrde o naručivanju robe, informacije o povraćaju robe, ili ostala obaveštenja koja se tiču izdavačke kuće PUBLIK PRAKTIKUM. 
                    </p>
                    <h3>
                        Zaštita poverljivih podataka o transakciji
                    </h3>
                    <p>
                        Prilikom unošenja podataka o platnoj kartici, poverljive informacija se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije kriptografske tehnologije.
                        <br />
                        Sigurnost podataka prilikom kupovine, garantuje procesor platnih kartica, Banca Intesa ad Beograd, pa se tako kompletni proces naplate obavlja na stranicama banke. Niti jednog trenutka podaci o platnoj kartici nisu dostupni našem sistemu.
                    </p>
                    <h3>
                        Korišćenje Internet kolačića (Cookies), ostalih tehnologija i trećih lica za prikupljanje podataka
                    </h3>
                    <p>
                        Internet kolačić je mala datoteka koju Web stranica ili e-mail poruka šalje Vašem Internet čitaču, koji ga onda može sačuvati na Vašem hard disku. Naša Web stranica i e-mail poruka koriste Internet kolačiće kako bi maksimalno personalizovali proceduru kupovine na našim Web stranicama. Kolačić simulira neprekidnu konekciju. Oni omogućavaju čuvanje informacija o Vašim podešavanjima i sesijama i dozvoljava pristup Web stranicama bez ponovne personalizacije. 
                    </p>
                    <p>
                        Web čitač (browser) se može podesiti da ne prihvata Internet kolačiće. U tom slučaju ćete biti u mogućnosti da gledate naše Web stranice, ali nećete moći da kreirate korisnički nalog ili da napravite porudžbinu. 
                    </p>
                    <p>
                        Naš Web server automatski sakuplja informacije kao što su adresa (URL) Web stranica sa koje ste došli, koje stranice ste posetili na našoj Internet prezentaciji, koji Web čitač koristite za pregled naših Web stranica i ostale statističke podatke koji nam pomažu da shvatimo i razumemo kako posetioci koriste naše Web stranice. 
                    </p>
                    <p>
                        Web stranica publikpraktikum.rs koristi servise koje obezbeđuju treća lica i koja u naše ime, putem interneta, analiziraju saobraćaj i posećenost naših Web stranica. Na taj način mogu biti prikupljane anonimne informacije o Vašim posetama našim Web stranicama i Vašoj interakciji sa našim oglašavanjima. Za te potrebe se mogu koristiti tehnologije slične onima koje smo opisali u prethodnim pasusima, kako bi se izmerila efikasnost oglašavanja i e-mailova. 
                    </p>
                    <h3>
                        Ažuriranje ličnih podataka
                    </h3>
                    <p>
                        publikpraktikum.rs želi da Vaši lični podaci budu tačni i kompletni.
                        <br />
                        - Ako ste kreirali korisnički nalog na publikpraktikum.rs Web stranici, možete ažurirati Vaše podatke klikom na Moj meni. 
                        <br />
                        - Opciji Moj meni možete pristupiti klikom na svoje ime nakon registracije.
                        <br />
                        - A zatim sajtu kroz opciju Izmeni podatke možete sami promeniti podatke.
                    </p>
                    <h3>
                        Sakupljanje informacija od dece
                    </h3>
                    <p>
                        Zalažemo se za zaštitu prava dece! Stoga PUBLIK PRAKTIKUM neće svesno ili namerno sakupljati lične podatke od dece putem Interneta, koja su mlađa od 13 godina. 
                    </p>
                    <h3>
                        Kontakt
                    </h3>
                    <p>
                        Ako budete imali bilo kakvih pitanja ili pritužbi koje se tiču privatnosti i sigurnosti Vaših podataka, ili ukoliko imate saznanja ili sumnju o narušavanju privatnosti ili sigurnosti, molimo Vas da nas kontaktirate na neki od sledećih načina: 
                    </p>
                    <ol>
                        <li>
                            Pozovite nas na jedan od sledećih brojeva telefona: 011/42-02-544 ili 011/42-02-545
                        </li>
                        <li>
                            Pošaljite e-mail na: office@publikpraktikum.rs
                        </li>
                        <li>
                            Pošaljite pismo na poštansku adresu: Dobrovoljačka 10/I, 11080 Zemun
                        </li>
                    </ol>
                    <p>
                        Pun naziv pravnog subjekta:
                        <b> PUBLIK PRAKTIKUM  DRUŠTVO ZA IZDAVAŠTVO, PROIZVODNJU, PROMET I USLUGE DOO BEOGRAD (ZEMUN)</b>
                        <br />
                        Sedište:
                        <b>Zemun, Dobrovoljačka 10/I</b>
                        <br />
                        Delatnost i šifra delatnosti:
                        <b>5811-Izdavanje knjiga</b>
                        <br />
                        Matični broj:
                        <b>17591967</b>
                        <br />
                        Poreski broj:
                        <b>103635212</b>
                    </p>
                    <br />
                    <p>
                        Adresa za slanje pošte:
                        <b>Dobrovoljačka 10/I, 11080 Zemun</b>
                        <br />
                        Kontakt telefon:
                        <b>+381 11 4202 544 </b>
                        <br />
                        Kontakt e-mail:
                        <b>office@publikpraktikum.rs</b>
                        <br />
                        Web adresa:
                        <b>publikpraktikum.rs</b>
                    </p>
                </div>
            </div>
        );
    }

}

export default Rules;