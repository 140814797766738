import React, { Component } from 'react';
// import {BrowserRouter as Router, Route, Link, Read} from 'react-router-dom';
import Categories from '../components/Categories';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import duix from 'duix';
//import Pager from '../components/Pager';
import {Config} from '../components/Config';
import ReactPaginate from 'react-paginate';

//import ReactGA from 'react-ga';

const queryString = require('query-string');



// const Info = props => (
  
//     <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
 
// );

// function images(image){
//     return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
//   }

class Products extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            page: 1,
            pageUpNumber: 0,
            pageDownNumber: 0
        }
    }

    

    addToWishlist(product){

    if(Auth.isAuthenticated){
        Service.addToWishlist({
            productId: product.id, 
            Auth
            },()=>{
                duix.set('wishlistItems', 1);
                duix.set('modal', 'Proizvod je dodat u listu želja.');
            });
        }else{
            alert('Morate biti ulogovani da biste koristili listu želja.');
        }
    }

    updateProducts = () => {

        if(!this.props.match.params.filter){
            Service.get('/products/getPending',{page: this.page, perPage: Config.perPage},(res)=>{
                this.setState({products: res.data.products, numberOfProducts: res.data.total_products});
                
            });
            return;
        }
        
      }
      getParams = queryString.parse(this.props.location.search);
      page;

      componentDidMount() {

          if(this.getParams.page){
                this.page = parseInt(this.getParams.page);
            }else{
                this.page = 1;
            }

            //ReactGA.initialize(Service.gaCode);
            //ReactGA.pageview(window.location.pathname + window.location.search);
            //console.log('ga', window.location.pathname + window.location.search)
        this.updateProducts();
      }

    changePage = page => {
        let link;
        if(page.selected){
            link = `${window.location.href.split('?')[0]}?page=${page.selected + 1}`;
           // window.location.href = link;
        }else{
            link = `${window.location.href.split('?')[0]}`;
            
        }
        window.location.href = link;
    }

    render() {
        return(
            
            <div>
                <div className="left-content">
                    {/* <h3>Kategorije</h3> */}
                    <Categories />
                </div>
                <div className="right-content">
                <h3>U pripremi</h3>
                {this.state.products.map(function(item, index){


                    return(
                        <div className="product-container" key={index} >
                          <div className="product-image-container">
                          <a href={`/proizvod/${item.id}/${item.slug}`}><img alt="" src={Service.images(item.slug.toLowerCase() + '.jpg')} width="100" /></a>
                          </div>
                          <h3>{item.title}</h3>
                          <div className="product-container-info">
                            {/* <Info label="cena" value={item.price} /> */}
                            {/* <Info label="autor" value={item.author} /> */}
                            {item.authors.map(function(author, index){
                                return(
                                    <span key={index} className="authors-span">
                                        {author.link &&
                                            <a href={author.link}><h4>{author.name}</h4></a>
                                        }
                                        {!author.link &&
                                            <h4>{author.name}</h4>
                                        }
                                    </span>
                                )}, this)
                            }
                            {/* <Info label="povez" value={item.binding} /> */}
                            {/* <Info label="format" value={item.dimension} /> */}
                            <button type="button" className="add-to-cart-button wishlist-button" onClick={()=>{this.addToWishlist(item)}}>DODAJ U LISTU ŽELJA</button>

                          </div>
                        </div>
                    );
                    },this)
                  }

                  {this.state.products.length < 1 &&
                    <p className="no-products" >Nema pronadjenih proizvoda.</p>
                  }

                  {/* <Pager numberOfProducts={this.state.numberOfProducts} currentPage={this.page} perPage={Config.perPage}/> */}
                    <ReactPaginate
                        pageCount={Config.getNumberOfPages(this.state.numberOfProducts)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        previousLabel={'<'}
                        nextLabel={'>'}
                        forcePage={this.page - 1}
                        containerClassName={'pager-container'}
                        activeClassName={'pager-selected'}
                        onPageChange={this.changePage}
                    />
                </div>
            </div>

        );
    }

}

export default Products;