import React, { Component } from 'react';
import TopProducts from '../components/TopProducts';
import NewsSidebar from '../components/NewsSidebar';


class Contact extends Component{

    render(){
        return(
            <div>
                <div className="left-content">
                    <TopProducts />
                    <NewsSidebar />
                </div>
                <div className="right-content">
                    <h1>Publik Praktikum Kontakt</h1>
                    <p>Radno vreme: Ponedeljak – petak: 08.00 – 16.00 h Subota: 09.00 -13.00 h</p>
                    <p>Tel/Fax: +381 11 4202 544 +381 11 4202 545</p>
                    <p>Email: <a href="mailto:office@publikpraktikum.rs" target="_blank" rel="noopener noreferrer">office@publikpraktikum.rs</a></p>
                    <p>Adresa: Dobrovoljačka 10/I, 11080 Zemun</p>
                    <div className="map">
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m8!1m3!1d1984.753646731077!2d20.400518894951713!3d44.8478423713163!3m2!1i1024!2i768!4f13.1!4m6!3e6!4m0!4m3!3m2!1d44.8478721!2d20.4008955!5e0!3m2!1sen!2srs!4v1560259653013!5m2!1sen!2srs" allowfullscreen></iframe> */}
                        <iframe title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1414.340472444092!2d20.400371958176315!3d44.848430563903904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a65b6b3c2b153%3A0x5dfa7beaf52a012f!2sPUBLIK%20PRAKTIKUM!5e0!3m2!1sen!2srs!4v1567782379624!5m2!1sen!2srs" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        );
    }

}

export default Contact;