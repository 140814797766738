import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import {Link} from 'react-router-dom';
import {Cart} from '../components/Cart';
import Shipping from '../components/Shipping';
import InputMask from 'react-input-mask';
import Selector from '../components/Selector';


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

var validator = require("email-validator");

class CartPage extends Component {

constructor(props){
    super(props);
    this.state = {
        cartItems: [],
        cartTotal: 0,
        productsTotal: 0,
        shippingPrice: 0,
        name: '',
        nameError: 'none',
        address: '',
        addressError: 'none',
        city: '',
        cityError: 'none',
        zipCode: '',
        zipCodeError: 'none',
        email: '',
        emailError: 'none',
        municipality: '',
        telephone: '',
        telephoneError: 'none',
        productsError: 'none',
        country: 'Srbija',
        paymentMethod: '',
        paymentMethodError: 'none',
        inputErrors: 'none',
        clientId: "13IN060311",
        orderId: '',
        amount: '',
        okUrl: "",
        failUrl: "",
        transactionType: "Auth",
        rnd: '',
        currency: "941",
        shopUrl: '',
        hashAlgorithm: '',
        storeType: '',
        encoding: '',
        hash: '',
        loaderVisible: false,
        source:'website',
        admin: false,
        note: '',
        hasShippingAddress: false,
        shippingData: {}
    }
    
    this.formRef = React.createRef();
}

componentDidMount(){
    
    // this.updateCart();
    setTimeout(()=>{
        this.updateCart();
        this.getUserData();
        if(
            Auth.email === 'dvojno@gmail.com' || 
            Auth.email === 'admin@publikpraktikum.com' ||
            Auth.email === 'admin.ivan@publikpraktikum.com' ||
            Auth.email === 'admin.milica@publikpraktikum.com' ||
            Auth.email === 'admin.zorana@publikpraktikum.com' ||
            Auth.email === 'admin.olja@publikpraktikum.com' ||
            Auth.email === 'admin.jaca@publikpraktikum.com' ||
            Auth.email === 'admin.milos@publikpraktikum.com' ||
            Auth.email === 'admin.bojana@publikpraktikum.com'
        ){
            this.setState({admin: true});
        }
    }, 500);
    // setTimeout(()=>{this.getUserData()}, 500);
}

updateShippingData=(data)=>{
    this.setState({shippingData: data});
}

getUserData=()=>{
    Service.get('/users/getInfo', {},(res)=>{
        if(res.success){
            this.setState({
                address: res.data.address,
                city: res.data.city,
                country: 'Srbija',
                email: res.data.email,
                municipality: res.data.municipality,
                name: res.data.name,
                telephone: res.data.phone,
                zipCode: res.data.zip_code
            })
        }
    })
}

getCartProductsQuantities(cartItems){
        let r = [];
        for(var p in cartItems){
            r.push({id: cartItems[p].id, quantity: cartItems[p].quantity});
        }
        return r;
}

updateCart=()=>{

    let ce = Cart.getCartElements();
    this.setState({cartItems: ce},()=>{
        Service.getCart({cartProducts: this.getCartProductsQuantities(this.state.cartItems)},(result)=>{
            this.setState({cartTotal: result.data.cartTotal, 
                productsTotal: result.data.productsTotal,
                shippingPrice: result.data.shippingPrice,
                //cartItems: result.data.cartProudcts
                },()=>{
                    //this.props.updateCartDisplay();
                    //duix.set('cartItems', 1);
                });
        });
    });

    
    
}

createOrder = () => {


    let products = [];
    this.state.cartItems.map((item)=>{
        products.push({id: item.id, quantity: item.quantity});
        return 1;
    });

    let checkedProducts = true;
    // console.log(products.length);
    if(!products.length){
        
        this.setState({productsError: 'block'});
        checkedProducts = false;
    }else{
        this.setState({productsError: 'none'});
    }
    
    let checked = true;
    if(!this.state.name){
        this.setState({nameError: 'block'});
        checked = false;
    }else{
        this.setState({nameError: 'none'});
    }

    if(!this.state.address){
        this.setState({addressError: 'block'});
        checked = false;
    }else{
        this.setState({addressError: 'none'});
    }

    if(!this.state.city){
        this.setState({cityError: 'block'});
        checked = false;
    }else{
        this.setState({cityError: 'none'});
    }

    if(!this.state.zipCode){
        this.setState({zipCodeError: 'block'});
        checked = false;
    }else{
        this.setState({zipCodeError: 'none'});
    }

    if(!this.state.email || !validator.validate(this.state.email) ){
        this.setState({emailError: 'block'});
        checked = false;
    }else{
        this.setState({emailError: 'none'});
    }

    if(!this.state.telephone){
        this.setState({telephoneError: 'block'});
        checked = false;
    }else{
        this.setState({telephoneError: 'none'});
    }

    if(!this.state.paymentMethod){
        this.setState({paymentMethodError: 'block'});
        checked = false;
    }else{
        this.setState({paymentMethodError: 'none'});
    }


    if(!checked){
        this.setState({inputErrors: 'block'});
        return; 
    }else{
        this.setState({inputErrors: 'none'});
    }

    if(!checkedProducts){
        return;
    }

    this.setState({loaderVisible: true});

    Service.createOrder({
            Auth, 
            products,
            name: this.state.name,
            billingAddress: this.state.address,
            // shippingAddress: this.state.address,
            city: this.state.city,
            municipality: this.state.municipality,
            phone: this.state.telephone,
            email: this.state.email,
            zipCode: this.state.zipCode,
            country: this.state.country,
            paymentMethod: this.state.paymentMethod,
            source: this.state.source,
            note: this.state.note,
            hasShippingAddress: this.state.hasShippingAddress,
            shippingName: this.state.shippingData.name,
            shippingAddress: this.state.shippingData.address,
            shippingCity: this.state.shippingData.city,
            shippingMunicipality: this.state.shippingData.municipality,
            shippingPhone: this.state.shippingData.telephone,
            shippingZipCode: this.state.shippingData.zipCode,
            shippingCountry: this.state.shippingData.country
        
        },(response)=>{

        this.setState({
            hash: response.data.form.hash,
            rnd: response.data.form.rnd,
            orderId: response.data.form.orderId,
            amount: response.data.form.amount,
            okUrl: response.data.form.urlSuccess,
            failUrl: response.data.form.urlFail,
            clientId: response.data.form.clientId,
            shopUrl: response.data.form.shopUrl,
            hashAlgorithm: response.data.form.hashAlgorithm,
            transactionType: response.data.form.transactionType,
            storeType: response.data.form.storeType,
            currency: response.data.form.currency,
            lang: response.data.form.lang,
            encoding: response.data.form.encoding,
            paymentMethod: response.data.form.paymentMethod,  //@todo: uzeti sve podatke iz backenda
            orderHash: response.order_hash
        },()=>{

            //if payment method is credit card submit form
            if(this.state.paymentMethod === 'cc'){
                var form = this.formRef.current;
                // show loader
                this.setState({loaderVisible: true});
                form.submit();
            }else{
                this.props.history.push('/success/pp/'+ response.order_hash);
            }
        });
    });
    
}

handleInput=(event)=>{
    this.setState({[event.target.id]: event.target.value},
        ()=>{}
    );
    
}

handleNoteChange = (event) => {
    this.setState({note: event.target.value});
}

    removeProduct=(productId)=>{
        if(window.confirm('Da li ste sigurni da želite da izbacite proizvod iz korpe?')){
            Cart.removeFromCart(productId);
            this.updateCart();
        }
        
    }

    changeQuantity = (product, value) => {
        if(value === 'add'){
            Cart.addToCart(product);
        }

        if(value === 'remove'){
            Cart.removeOneFromCart(product);
        }

        this.updateCart();

    }
    
handleShippingAddress = event => {
    console.log(event.target.checked)
    this.setState({ hasShippingAddress: event.target.checked })
}


render() {

return (
    <div>
        <h2>Korpa</h2>
        <div className="cart-product cart-product-head">
                <div className="image">1. Sadržaj Korpe</div>
                <div className="title">Naslov</div>
                <div className="quantity">Količina</div>
                <div className="price">Cena</div>
                <div className="trash"></div>
            </div>
        
        {   this.state.cartItems.length > 0 &&
            <div>
            {this.state.cartItems.map(function(item, index){
            return(
                <div className="cart-product">
                    <div className="image"><img src={Service.images(item.product.slug + '.jpg')} alt={item.product.slug} /></div>
                    <div className="title">{item.product.title}</div>
                    <div className="quantity">
                        <button onClick={()=>{this.changeQuantity(item.product, 'remove')}}>-</button>
                        <span>{item.quantity}</span>
                        <button onClick={()=>{this.changeQuantity(item.product, 'add')}}>+</button>
                    </div>
                    <div className="price">{item.product.price}</div>
                    <div className="trash"><button onClick={()=>{this.removeProduct(item.product.id)}}><i class="fas fa-trash"></i></button></div>
                </div>
            )
            }, this)}
                <div className="cart-total">
                    <p>Ukupno: {this.state.productsTotal} RSD</p>
                    <p>Isporuka: {this.state.shippingPrice} RSD</p>
                    <h3><strong>Total: {this.state.cartTotal} RSD</strong></h3>
                    <h5>Sve cene su sa uračunatim PDV-om.</h5>
                    <h3>Besplatna dostava za porudžbine preko 2000 dinara.</h3>
                </div>
            </div>
        }
        
        { this.state.cartItems.length <= 0 &&
            <p>Vasa korpa je prazna</p>  
        }

        <div className="cart-product cart-product-head">
            <div className="head-title">2. Podaci za isporuku</div>

        </div>
        <div className="cart-inputs">
            <h3>Ime i prezime</h3>
            <input type="text" id='name' value={this.state.name} onChange={this.handleInput} />
            <p className="error" style={{display: this.state.nameError}}>* morate da unesete ime i prezime.</p>
            <h3>Adresa</h3>
            {/* <Selector route='/orders/getStreets' filters={{competition: this.state.competition, brands: this.state.selectedBrands, subBrands: this.state.selectedSubBrands}} placeholder="Adresa..."/> */}
            <input type="text" id='address' value={this.state.address} onChange={this.handleInput} />
            <p className="error" style={{display: this.state.addressError}}>* morate da unesete adresu.</p>
            <h3>Opština</h3>
            <input type="text" id='municipality' value={this.state.municipality} onChange={this.handleInput} />
            <h3>Grad</h3>
            <input type="text" id='city' value={this.state.city} onChange={this.handleInput} />
            <p className="error" style={{display: this.state.cityError}}>* morate da unesete grad.</p>
        </div>
        <div className="cart-inputs">
            <h3>Poštanski broj</h3>
            <input type="text" id='zipCode' value={this.state.zipCode} onChange={this.handleInput} />
            <p className="error" style={{display: this.state.zipCodeError}}>* morate da unesete poštanski broj.</p>
            <h3>Država</h3>
            {/* <input type="text" id='country' value={this.state.country} onChange={this.handleInput} /> */}
            <input type="text" id='country' value='Srbija' readOnly/>
            <h3>Broj telefona</h3>
            {/* <InputMask {...this.props} mask="+381 69 999 9999" maskChar=" " />; */}
            <input type="text" id='telephone' value={this.state.telephone} onChange={this.handleInput} />
            <p className="error" style={{display: this.state.telephoneError}}>* morate da unesete vaš broj telefona.</p>
            <h3>Email</h3>
            <input type="text" id='email' value={this.state.email} onChange={this.handleInput} readOnly={this.state.admin}/>
            <p className="error" style={{display: this.state.emailError}}>* morate da unesete vaš email.</p>
            
            {this.state.admin === true &&
                <fragment>
                <p>Admin: Odaberi odakle je došla porudžbina:</p>
                    <select id='source' value={this.state.source} onChange={this.handleInput}>
                        <option value='website'>website</option>
                        <option value='instagram'>Instagram</option>
                        <option value='facebook'>Facebook</option>    
                        <option value='telephone'>Telephone</option> 
                    </select>
                </fragment>
            }
        </div>
        <div className="cart-inputs">
            <h3>Napomena</h3>
            <textarea className="order-note" placeholder="Napišite napomenu u za isporuku..." value={this.state.note} onChange={this.handleNoteChange}></textarea>
            {/* <div>
                <h3>Nudimo brojne pogodnosti za registrovane kupce:</h3>
                <ul>
                    <li>Specijalni popusti za online kupovinu</li>
                    <li>Rođendanski popusti</li>
                    <li>Novogodišnji popusti</li>
                </ul>
                <Link to="/registracija" ><button className="add-to-cart-button" >Registrujte se!</button></Link>
            </div> */}
        </div>
        <br/><br/>
        <input id='scb' type="checkbox" style={{display: 'inline-block', verticalAlign: 'middle'}} value={this.state.hasShippingAddress} onChange={this.handleShippingAddress} />
        <label for="scb" style={{display: 'inline-block', verticalAlign: 'middle'}}> <b>Adresa isporuke</b> je drugačija od adrese plaćanja? Kliknite ovde i popunite podatke vezano za adresu isporuke</label>
        {this.state.hasShippingAddress === true &&                                  
            <Shipping updateShippingData={this.updateShippingData} />
        }


        {/* PAYMENT */}
        <div className="cart-product cart-product-head">
            <div className="head-title">3. Način isporuke i način plaćanja</div>
            {/* <div className="cart-head-half">4. Način plaćanja</div> */}
        </div>
        <div className="cart-inputs-billing">
            <input type="radio" className="cart-input-radio" name="shipping-method" value="0" checked="checked"/>
            <div>
                <h4>Kurirskom službom - RSD {Service.shippingFee},00</h4>
                <br />
                Porudžbine se vrše putem kuriske službe DailyExpress.
                <br />
                <br />
                <strong>Za porudžbine preko 2000 dinara dostava je besplatna.</strong>
            </div>
        </div>
        <div className="cart-inputs-billing">
            <input type="radio" className="cart-input-radio" name="billing-method" id="paymentMethod" value="cc" onChange={this.handleInput}/>
                <div>
                    <h4>Platnom karticom</h4>
                    Placanje karticom putem sigurnog sistema Banke Intese.
                </div>
            <br />
            <input type="radio" className="cart-input-radio" name="billing-method" id="paymentMethod" value="pp" onChange={this.handleInput}/>
                <div>
                    <h4>Pouzećem</h4>
                    Plaćanje kuriru prilikom dostave paketa.
                </div>
            <p className="error" style={{display: this.state.paymentMethodError}}>* morate da odaberete način plaćanja.</p>
        </div>

        



        <div className="cart-submit">
            
            Sve cene su iskazane sa uračunatim PDV-om - nema dodatnih troškova. <br />
            Klikom na dugme <strong>'NARUČI'</strong>, prihvatate <Link to="/pravila">uslove kupovine</Link>
            <br />
            { this.state.loaderVisible === false &&
                <button onClick={this.createOrder} >Naruči</button>
            }
            <Loader
                type="Oval"
                color="#01A7A5"
                height={100}
                width={100}
                timeout={100000} //3 secs
                visible={this.state.loaderVisible}
            />
        </div>
        <p className="error" style={{display: this.state.inputErrors, textAlign: 'center', width: '100%', position: 'relative', fontSize: '1.2em'}}>* Niste uneli sve potrebne podatke.</p>
        <p className="error" style={{display: this.state.productsError, textAlign: 'center', width: '100%', position: 'relative', fontSize: '1.2em'}}>* Vaša korpa je prazna, molimo vas da ubacite neki proizvod u korpu.</p>
        {/* CC form */}

        <form action={Service.paymentUrl} ref={this.formRef} id="form" method='POST'>
            <input type="hidden" name="clientid" value={this.state.clientId}/>
            <input type="hidden" name="storetype" value={this.state.storeType} />
            <input type="hidden" name="hashAlgorithm" value={this.state.hashAlgorithm} />
            <input type="hidden" name="hash" value={this.state.hash} />
            <input type="hidden" name="trantype" value={this.state.transactionType} />
            <input type="hidden" name="amount" value={this.state.amount} />
            <input type="hidden" name="currency" value={this.state.currency} />
            <input type="hidden" name="oid" value={this.state.orderId} />
            <input type="hidden" name="okUrl" value={this.state.okUrl}/>
            <input type="hidden" name="failUrl" value={this.state.failUrl} />
            <input type="hidden" name="lang" value={this.state.lang} />
            <input type="hidden" name="rnd" value={this.state.rnd} />
            <input type="hidden" name="encoding" value={this.state.encoding} />
            <input type='hidden' name='shopurl' value={this.state.shopUrl} />
        </form>
    </div>
    );

    
}

}

export default CartPage;