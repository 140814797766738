import React, { Component } from 'react';

import TopProducts from '../components/TopProducts';
import NewsSidebar from '../components/NewsSidebar';
import Service from '../components/Service';

//import ReactGA from 'react-ga';

class Krcko extends Component {


   constructor(props){
        super(props);
        this.state = {
            firstLastName: '',
            email: '',
            address: '',
            city: '',
            phone: '',
            subscription: '',
            subscriptionNumbers: ''
        }
        
    }

    componentDidMount(){
        //ReactGA.initialize(Service.gaCode);
        //ReactGA.pageview(window.location.pathname + window.location.search);
        // console.log(Service.gaCode, window.location.pathname + window.location.search)
    }

    selectSubscription=(event)=>{
        this.setState({subscription: event.target.value});
 
    }

    editInputValue= (event) =>{
        this.setState({[event.target.id]: event.target.value});
    }

    subscribe=()=>{
        Service.krckoSubscribe({
            firstLastName: this.state.firstLastName,
            email: this.state.email,
            address: this.state.address,
            city: this.state.city,
            phone: this.state.phone,
            subscription: this.state.subscription,
            subscriptionNumbers: this.state.subscriptionNumbers,
        },
            (res)=>{
                if(res.success){
                    this.props.history.push('/message/krcko');
                }else{
                    alert('Prijava nije uspela. Molimo vas pokušajte ponovo');
                }
            }
        );
    }
    
    render(){
        return(
            <div>
                <div className="left-content">
                    <TopProducts />
                    <NewsSidebar />
                    {/* <Categories /> */}
                </div>
                <div className="right-content">
                    <h1>
                        Krcko
                    </h1>

                    <h3>KRCKO - OD POSTANKA, PA DO DANAS</h3>

                    <p>
                        Časopis „Krcko“ je dečiji časopis poučnog i zabavnog sadržaja, namenjen mališanima uzrasta 5-7 godina. Prvi broj je izašao 1. septembra 2006. godine. 
                    </p>

                    <h4>
                        Sadržaj časopisa „Krcko“
                    </h4>
                    <p>
                        U časopisu možete pročitati sve ono što može izazvati interesovanje jednog savremenog radoznalog deteta: od pitanja istorije i postanka čoveka i života uopšte, preko raznih zanimljivosti o svetu u kome živimo, pojedinosti o biljnom i životinjskom svetu, biografija velikih naučnika, umetnika, sportista, novosti iz sveta muzike, filma i pozorišta, tekstova o naučnim dostignućima, o planeti Zemlji, modernoj tehnologiji, saveta o očuvanju zdravlja, pa sve do karakteristika modernog, savremenog društva i doba.<br />
                        Časopis promoviše pravo na mišljenje, toleranciju deteta prema drugačijem, obrazovanje, radoznalost, solidarnost, naučni princip, ravnopravnost bez obzira na poreklo, odgovornost prema sebi i društvenoj zajednici.<br /><br />
                        Pored stalnih rubrika, kao što su: KRCKOV POZDRAV, POVEZUJEMO, OTKRIVAMO, BOJIMO, STIHOVI, SAZNALI SMO, PUTOVANJA, ISTRAŽUJEMO, OTKRIVAMO, ZAPAŽAMO, LAVIRINT, NEKADA DAVNO, ODLEPI I ZALEPI, UMOTVORINE, MOZGALICE, ŽIVI SVET, ZANIMLJIVOSTI, IGRE SLOVIMA, MUZIČKA VRTEŠKA, PRIČA U SLICI, TVOJA PRIČA U SLICI, KRCKOV SUDOKU, KRCKOVA UKRŠTENICA, DRAMSKI KUTAK, ZABAVNI KUTAK, ZANIMANJA, IGRE BROJEVIMA, KRCKOVE IGRE, KRCKOVE PITALICE, KRCKOVA GALERIJA, 
                        u svakom broju vas očekuju i kreativni, zabavni kartonski umeci i nalepnice, nagrade za najlepše dečije radove, nagradne igre (o kojima možete saznati nešto više u prvim, septembarskim brojevima časopisa - u kojima dobijate talon za lepljenje kupona koje ćete dobijati u svakom broju do juna, nakon čega sledi izvlačenje dobitnika), iznenađenja za najvernije čitaoce i još mnogo toga!
                    </p>

                    <h4>
                        Kada izlazi i gde možete kupiti
                    </h4>
                    <p>
                        Časopis „Krcko“ izlazi jednom mesečno. Možete ga kupiti na svim kioscima širom Srbije, ili se pretplatiti preko vrtića u dogovoru sa vaspitačima ili direktno pozivom na jedan od brojeva naše izdavačke kuće (011/4202 544 i 011/4202 545) ako želite da vam časopis stiže svakog meseca na kućnu adresu. Stare brojeve časopisa takođe možete kupiti pozivom na neki od već navedenih brojeva telefona.
                        Zainteresovani mogu popuniti obrazac za prijavu na dnu strane u delu teksta pod nazivom „Pretplata”.
                        Ukoliko bude bila potrebna pomoć, ili imate nekih nejasnoća možete se direktno obratiti našim kolegama u izdavačkoj kući.
                    </p>

                    <h4>
                        Opis:
                    </h4>
                    <p>
                        Autor:
                        <i>Grupa autora</i>
                        <br />
                        Format:
                        <i>21x30 cm, pun kolor, meki povez</i>
                        <br />
                        <i>Ćirilica, 34 strane + umetak 2 strane</i>
                    </p>
                    <h3>
                        MALI KRCKO - OD POSTANKA, PA DO DANAS
                    </h3>
                    <p>
                        Časopis „Mali Krcko“ je takođe dečiji časopis poučnog i zabavnog sadržaja, namenjen mališanima uzrasta 3-5 godina. Prvi broj je izašao 1. septembra, 2007. godine.
                    </p>
                    <h4>
                        Sadržaj časopisa „Mali Krcko“
                    </h4>
                    <p>
                        Ovde možete naći odgovore na sva pitanja koja postavljaju vaši mališani. Pored osnovnih odgovora na pitanja postavljaju se i nova koja decu podstiču na razmišljanje i akciju, obrazuju, informišu, zabavljaju i afirmišu dečije stvaralaštvo. Možete naći niz odgovora o prošlosti čoveka i života, preko raznih zanimljivosti o svetu u kome živimo, pojedinosti o biljnom i životinjskom svetu, saveta o očuvanju zdravlja, pa sve do karakteristika modernog, savremenog društva i doba. 
                        Pored stalnih rubrika, kao što su: KRCKOV POZDRAV, SPOJ CRTICE I OBOJ, PRONAĐI RAZLIKE, STIHOVI, OBOJ PO SLICI, ODLEPI I ZALEPI, SLAGALICA, LAVIRINT, UOČI I POVEŽI, ŽIVOTINJE, BILJKE, OBOJ PO OBLICIMA, MOZGALICA, MUZIČKI KUTAK, VEŽBALICA, PRONAĐI SAKRIVENO, PRIČA U SLICI, JEDNA PRIČA, NAPRAVI SAM, MALA GALERIJA, u svakom broju vas očekuju i kreativni, zabavni kartonski umeci i nalepnice, nagrade za najlepše dečije radove, nagradne igre (o kojima možete saznati nešto više u prvim, septembarskim brojevima časopisa - u kojima dobijate talon za lepljenje kupona koje ćete dobijati u svakom broju do juna, nakon čega sledi izvlačenje dobitnika), iznenađenja za najvernije čitaoce i još mnogo toga!
                    </p>
                    <h4>
                        Kada izlazi i gde možete kupiti
                    </h4>
                    <p>
                        Časopis „Mali Krcko“ izlazi jednom mesečno. Možete ga kupiti na svim kioscima širom Srbije, ili se pretplatiti preko vrtića u dogovoru sa vaspitačima ili direktno pozivom na jedan od brojeva naše izdavačke kuće (011/4202 544 i 011/4202 545) ako želite da vam časopis stiže svakog meseca na kućnu adresu. Stare brojeve časopisa takođe možete kupiti pozivom na neki od već navedenih brojeva telefona.
                        Zainteresovani mogu popuniti obrazac za prijavu na dnu strane u delu teksta pod nazivom “Pretplata”.
                        Ukoliko bude bila potrebna pomoć, ili imate nekih nejasnoća možete se direktno obratiti našim kolegama u izdavačkoj kući.
                    </p>
                    <h4>
                        Opis:
                    </h4>
                    <p>
                        Autor:
                        <i>Grupa autora</i>
                        <br />
                        Format:
                        <i>21x30 cm, pun kolor, meki povez</i>
                        <br />
                        <i>Ćirilica, 22 strane + umetak 2 strane</i>
                    </p>
                    <h2>Pretplata</h2>
                    <div>
                        <h3>Pretplaćujem se na:</h3>
                        <br />
                        <div className="control" onChange={this.selectSubscription}>
                            <label className="radio">
                                <input type="radio" name="foobar" value="k-god-10-sep-jun-2000din"/>
                                Krcko godišnja pretplata (10 brojeva) - od septembra do juna 2000 dinara
                            </label>
                            <br />
                            <label className="radio">
                                <input type="radio" name="foobar" value="k-polugod-5-sep-jan-1000din"/>
                                Krcko polugodišnja pretplata (5 brojeva) - od septembra do januara 1000 dinara
                            </label>
                            <br />
                            <label className="radio">
                                <input type="radio" name="foobar" value="k-polugod-5-feb-jun-1000din" />
                                Krcko polugodišnja pretplata (5 brojeva) - od februara do juna 1000 dinara
                            </label>
                            <br />
                            <label className="radio">
                                <input type="radio" name="foobar" value="mk-god-10-sep-jun-1800din" />
                                Mali Krcko godišnja pretplata (10 brojeva) - od septembra do juna 1800 dinara
                            </label>
                            <br />
                            <label className="radio">
                                <input type="radio" name="foobar" value="mk-polugod-5-sep-jan-900din" />
                                Mali Krcko polugodišnja pretplata (5 brojeva) - od septembra do januara 900 dinara
                            </label>
                            <br />
                            <label className="radio">
                                <input type="radio" name="foobar" value="mk-polugod-5-feb-jun-900din" />
                                Mali Krcko polugodišnja pretplata (5 brojeva) - od februara do juna 900 dinara
                            </label>  
                        </div>
                    </div>

                    <p>
                        Broj komada (pretplata)
                        <br />
                        <input type="text" id="subscriptionNumbers" value={this.state.subscriptionNumbers} onChange={this.editInputValue}/>
                        <br />

                        Ime i prezime
                        <br />
                        <input type="text" id="firstLastName" value={this.state.firstLastName} onChange={this.editInputValue}/>

                        <br />
                        Adresa (ulica i broj) za isporuku
                        <br />
                        <input type="text" id="address" value={this.state.address} onChange={this.editInputValue}/>

                        <br />
                        Poštanski broj i mesto
                        <br />
                        <input type="text" id="city" value={this.state.city} onChange={this.editInputValue}/>

                        <br />
                        Telefon
                        <br />
                        <input type="text" id="phone" value={this.state.phone} onChange={this.editInputValue}/>

                        <br />
                        Email
                        <br />
                        <input type="text" id="email" value={this.state.email} onChange={this.editInputValue}/>

                    </p>
                    <button className="add-to-cart-button" style={{fontSize: '2em'}} onClick={this.subscribe}>Prijavi se</button>
                    <img src="/uplatnica.jpg" className='uplatnica' alt="Slika uplatnice"/>
                    <p>Platilac: Ime, adresa, grad</p>
                    <p>Svrha uplate: pretplata za časopis</p>
                    <p>Primalac: Publik praktikum DOO, Dobrovoljačka 10, 11080 Zemun</p>
                    <p>Račun: 330-52000260-44</p>
                    <br />
                    
                    <br />
                    <p>
                        <i>*Molimo vas da prilikom popunjavanja naloga za uplatu <em>upišete identično ime</em> kao i na porudžbenici kako bi se Krcko u najkraćem roku našao u vašim rukama!</i>
                    </p>

                </div>
            </div>
        );
    }

}

export default Krcko;