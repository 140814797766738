import React, { Component } from 'react';
import { Cart } from '../components/Cart';
import Service from '../components/Service';
import duix from 'duix';

// export const numberOfCartProducts = () => {
//     return Cart.getCartProductNumber();
// }

class CartDisplayMobile extends Component {

    constructor(props){
        super(props);
        this.state = {
            numberOfProducts: 0,
            total: 0
        }


    }

    test(){

    }

    unsubscribe = []
 
    componentDidMount(){
        
            Cart.initCart(); // get cart items from the local storage
            //this.interval = setInterval(() => {this.numberOfCartProducts()}, 1000);
            this.numberOfCartProducts();
            //this.setState({numberOfProducts: Cart.getCartProductsNumber()});
            this.unsubscribe[0] = duix.subscribe('cartItems', this.numberOfCartProducts);
        
    }

    componentWillUnmount(){
        //clearInterval(this.interval);
        this.unsubscribe[0]();
    }

    numberOfCartProducts=()=>{
        let ce = Cart.getCartProductsQuantities();
        Service.getCart({cartProducts: ce},(res)=>{
            this.setState({total: res.data.cartTotal});
        });
        this.setState({numberOfProducts: Cart.getCartProductsNumber()});
    }

    render(){


        return(

            <span>
                {/* <span className="cart-text">Korpa<br />{this.state.total} RSD.</span> */}
                <i className="fa fa-shopping-cart cart-image" ></i>
                <span className="mobile-cart-number"> {this.state.numberOfProducts} </span>
            </span>

        );
    }

}

export default CartDisplayMobile;
