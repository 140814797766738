import React, { Component } from 'react';
import Service from '../components/Service';
import duix from 'duix';
import { Auth } from '../components/Auth';


class WishDisplay extends Component {

    constructor(props){
        super(props);
        this.state = {
            productsCount: 0
        }
        this.interval = '';
    }

    // duix unsubscribe
    unsubscribe = [];

    updateWishlist=()=>{
        Service.getWishlist({Auth},(res)=>{
            this.setState({productsCount: res.data.products.length});
        },this);
    }
 
    componentDidMount(){
        this.updateWishlist();
        //update wishlist on duix update
        this.unsubscribe[0] = duix.subscribe('wishlistItems', this.updateWishlist);
    }

    componentWillUnmount(){
        this.unsubscribe[0]();
    }

    render(){
        return(
            <span>
                <span className="cart-text">Lista želja<br /><span className="heart">♥</span></span>
                {this.state.productsCount}
            </span>
        );
    }

}

export default WishDisplay;
