import { Auth } from '../components/Auth';

var api = 'http://api.pp.cekaonica.com/api';
var images = 'http://api.pp.cekaonica.com/media/';
var paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
var gaCode = 'UA-000000-01';
var secondaryImages = 'http://api.pp.cekaonica.com/secondary-images/';
if(window.location.origin === 'http://localhost:3001'){
    api = 'http://api.pp.cekaonica.com/api';
    images = 'http://api.pp.cekaonica.com/media/';
    paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
    secondaryImages = 'http://api.pp.cekaonica.com/secondary-images/';
}
if(window.location.origin === 'http://pp.cekaonica.com' || window.location.origin === 'https://pp.cekaonica.com'){
    api = 'http://api.pp.cekaonica.com/api';
    images = 'http://api.pp.cekaonica.com/media/';
    paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
    secondaryImages = 'http://api.pp.cekaonica.com/secondary-images/';
}

if(window.location.origin === 'http://publikpraktikum.cekaonica.com' || window.location.origin === 'https://publikpraktikum.cekaonica.com'){
    api = 'https://api.publikpraktikum.cekaonica.com/api';
    images = 'https://api.publikpraktikum.cekaonica.com/media/';
    paymentUrl = 'https://testsecurepay.eway2pay.com/fim/est3Dgate';
    secondaryImages = 'http://api.publikpraktikum.cekaonica.com/secondary-images/';
}

if(window.location.origin === 'http://publikpraktikum.rs' || window.location.origin === 'https://publikpraktikum.rs' || window.location.origin === 'http://www.publikpraktikum.rs' || window.location.origin === 'https://www.publikpraktikum.rs'){
    api = 'https://api.publikpraktikum.com/api';
    images = 'https://api.publikpraktikum.com/media/';
    paymentUrl = 'https://bib.eway2pay.com/fim/est3Dgate';
    gaCode = 'UA-80705254-1';
    secondaryImages = 'http://api.publikpraktikum.com/secondary-images/';
}




const Service = {

    paymentUrl: paymentUrl,
    gaCode: gaCode,
    secondaryImages: secondaryImages,
    shippingFee: 150,

    images(image){
        return images + image;
    },

    get(url, params, cb=null){
        params.Auth = Auth;

        fetch(`${api}${url}`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getProducts(cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              page: 1,
              perPage: 20,
              filter: ''
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getPendingProducts(cb=null){
        fetch(`${api}/products/getPending`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              page: 1,
              perPage: 50,
              filter: ''
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    getTopProducts(params,cb=null){
        fetch(`${api}/products/top`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
              if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
          )
    },

    getProductById(params,cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    getProductBySearch(params,cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNewProducts(cb=null){
        fetch(`${api}/products/getnewproductreleases`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            //body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    
    addToWishlist(params,cb=null){
        fetch(`${api}/users/addProduct`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    removeFromWishList(params,cb=null){
        fetch(`${api}/users/removeProduct`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getWishlist(params,cb=null){
        fetch(`${api}/users/getProducts`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getProductsByCategory(params, cb=null){

        fetch(`${api}/products/getByCategory`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(
              params
            )
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    register(email, password, name, subscribe, cb=null) {

        // return;
        fetch(`${api}/users/create`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
                name: name,
                subscribe: subscribe,
                email: email,
                password: password
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    login(email, password, cb=null) {
        fetch(`${api}/users/login`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              email: email,
              password: password
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }

            },
            (error) => {

            }
        )
    },

    createOrder(params,cb=null) {

        fetch(`${api}/orders/create`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    addComment(params,cb=null){
        fetch(`${api}/products/addComment`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getComments(params,cb=null){
        fetch(`${api}/products/getComments`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNews(cb=null){
        fetch(`${api}/news/all`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            //body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNewsSingle(params,cb=null){
        fetch(`${api}/news/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    krckoSubscribe(params,cb=null){
        fetch(`${api}/krcko/subscribe`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {

                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

      getCart(params,cb=null){

        fetch(`${api}/orders/getCart`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {

                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    rateProduct(params,cb=null){

        fetch(`${api}/products/rate`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    }
    
 
}

export default Service;