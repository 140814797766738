import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Service from '../components/Service';

class TopProducts extends Component {

    constructor(props){
        super(props);
        this.state = {
            products: []
        }

    }
 
    componentDidMount(){
        Service.getTopProducts({listName: 'pp-top'},
            (res)=>{
                this.setState({products: res.data.products});
            }
        );
    }

    // getTopProducts = () => {
    //     fetch(`${process.env.REACT_APP_API}/products/top`,
    //       {
    //         method: 'post',
    //         headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //         body: JSON.stringify({
              
    //         })
    //        })
    //       .then(res => res.json())
    //       .then(
    //         (result) => {
 
    //           this.setState({
    //             products: result.data.products
    //           });
              

    //         },
    //         (error) => {
    //           this.setState({
    //             // isLoaded: true,
    //             // error
    //           });
    //         }
    //       )
    // }

    

    render() {
        return (
            <div className="categories-container top-books">
                <h2>Top knjige</h2>
            {this.state.products.map(function(item, index){
                return(
                    <div key={index} className="top-product">
                        
                        <img alt="" src={Service.images(item.slug.toLowerCase()) + '.jpg'} width="100" />
                        <div>
                            <div className="product-authors">
                            {item.authors.map(function(author, index){
                                return(
                                    <span key={index} className="authors-span">
                                        <h4>{author.name}</h4>
                                    </span>
                                )}, this)
                            }
                            </div>
                            <Link to={`/proizvod/${item.id}/${item.slug}`}><h3>{item.title}</h3></Link>
                            <p>{item.price} RSD</p>
                        </div>
                    
                    </div>
                )
            },this)
            }
            </div>
        );
    }

}

export default TopProducts;
